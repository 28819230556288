<template>
  <div class="progress">
    <div class="progress__inner">
      <div
        class="progress__bar"
        :class="{ 'progress__bar--white-background': whiteBackground }"
      >
        <div class="progress__fill" :style="`--progress: ${progress}%`"></div>
      </div>
      <div v-if="showIndicator" class="progress__indicator">
        {{ value }} / {{ max }}
      </div>
    </div>
    <div v-if="$slots && $slots.default" class="progress__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  showIndicator: {
    type: Boolean,
    default: true,
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
});

const progress = computed(() => {
  if ((props.value / props.max) * 100 <= 100) {
    return (props.value / props.max) * 100;
  } else {
    return 100;
  }
});
</script>

<style lang="scss">
.progress__inner {
  display: flex;
  align-items: center;
}

.progress__bar {
  width: 100%;
  border-radius: $radius-small;
  background-color: $color-gray;
  height: 0.25rem;
  // border-width: 2px;
  position: relative;

  &--white-background {
    background: $color-white;
  }
}

.progress__fill {
  background-color: $color-green;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: $radius-small;
  width: var(--progress);
  animation: slideInPoints 2s ease-in-out forwards;
}

@keyframes slideInPoints {
  from {
    width: 0;
  }
}

.progress__content {
  color: $color-dark-gray;
  font-size: $text-sm;
  margin: 0.5rem 0;

  span {
    font-weight: $font-bold;
  }
}

.progress__indicator {
  white-space: nowrap;
  margin-left: $spacing-base;
  font-size: $text-sm;
  color: $color-gray;
}
</style>
